import { createSlice } from '@reduxjs/toolkit'


export const currentLoged = createSlice({
  name: 'CompanyUser',
  initialState : {
    data: {},
  },
  reducers: {
    getCurrentLoged: (state, action) => {
      state.data = action.payload
    },
    UpdateUserCompany: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { getCurrentLoged, UpdateUserCompany } = currentLoged.actions

export default currentLoged.reducer